/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { getLSValue } from 'helpers/localStorage'
import { getBrowserMetadata } from 'helpers/utils'
import api from 'services/api'

const {
  paymentMilesStart,
  paymentMilesSuccess,
  paymentMilesError,
  clearPaymentMilesState
} = createActions({
  PAYMENT_MILES_START: () => {},
  PAYMENT_MILES_SUCCESS: data => ({ data }),
  PAYMENT_MILES_ERROR: error => ({ error }),
  CLEAR_PAYMENT_MILES_STATE: () => {}
})

const paymentMiles = payload => async dispatch => {
  dispatch(paymentMilesStart())
  Object.assign(payload, getBrowserMetadata())

  try {
    const shoppingId = getLSValue('shoppingId')
    const response = await api.post(`${apiRoutes.PAYMENT_MILES_AR_PLUS}`, {
      shoppingId,
      ...payload
    })
    dispatch(paymentMilesSuccess(response.data))
  } catch (error) {
    dispatch(paymentMilesError(error.response ? error.response.data : error))
  }
}

export {
  paymentMiles,
  paymentMilesStart,
  paymentMilesSuccess,
  paymentMilesError,
  clearPaymentMilesState
}
