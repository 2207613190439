/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { LogOut } from 'helpers/flightOffers'
import { getBrowserMetadata } from 'helpers/utils'
import api from 'services/api'

const { giftMilesStart, giftMilesSuccess, giftMilesError, clearGiftMilesState } = createActions({
  GIFT_MILES_START: () => {},
  GIFT_MILES_SUCCESS: data => ({ data }),
  GIFT_MILES_ERROR: error => ({ error }),
  CLEAR_GIFT_MILES_STATE: () => {}
})

const giftMiles = payload => async dispatch => {
  dispatch(giftMilesStart())
  Object.assign(payload, getBrowserMetadata())

  try {
    const response = await api.post(`${apiRoutes.GIFT_MILES_AR_PLUS}`, {
      ...payload
    })

    dispatch(giftMilesSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearGiftMilesState())
    }

    dispatch(giftMilesError(error.response ? error.response.data : error))
  }
}

export { giftMiles, giftMilesStart, giftMilesSuccess, giftMilesError, clearGiftMilesState }
